<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.patients") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
        "
      >
        <el-button size="mini" @click="drawerCreate = true">
          <i class="el-icon-circle-plus-outline"></i> {{ $t("message.create") }}
        </el-button>
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Пациенты "
          name="Пациенты.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete">
            Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'patients'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.first_name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.surname"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patronymic"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.phone"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.born_date"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.social_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.code"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.gender"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.first_name.show">
            <el-input
              size="mini"
              v-model="filterForm.first_name"
              :placeholder="columns.first_name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.surname.show">
            <el-input
              size="mini"
              v-model="filterForm.surname"
              :placeholder="columns.surname.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.patronymic.show">
            <el-input
              size="mini"
              v-model="filterForm.patronymic"
              :placeholder="columns.patronymic.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.phone.show">
            <el-input
              size="mini"
              v-model="filterForm.phone"
              :placeholder="columns.phone.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.born_date.show">
            <el-date-picker
              v-model="filterForm.born_date"
              :placeholder="columns.born_date.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.social_id.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.social_id.title"
              size="mini"
              v-model="filterForm.social_id"
            >
              <el-option
                v-for="item in socials"
                :key="item.name + item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.code.show">
            <el-input
              size="mini"
              v-model="filterForm.code"
              :placeholder="columns.code.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.gender.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.gender.title"
              size="mini"
              v-model="filterForm.gender"
            >
              <el-option :label="$t('message.male')" value="man"></el-option>
              <el-option
                :label="$t('message.female')"
                value="woman"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="patient in list" :key="patient.id">
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.first_name.show">
            {{ patient.first_name | uppercaseFirst }}
          </td>
          <td v-if="columns.surname.show">
            {{ patient.surname | uppercaseFirst }}
          </td>
          <td v-if="columns.patronymic.show">
            {{ patient.patronymic | uppercaseFirst }}
          </td>
          <td v-if="columns.phone.show">{{ patient.phone }}</td>
          <td v-if="columns.born_date.show">{{ patient.born_date }}</td>
          <td v-if="columns.social_id.show">
            {{ patient.social ? patient.social.name : "" }}
          </td>
          <td v-if="columns.code.show">{{ patient.code }}</td>
          <td v-if="columns.gender.show">
            <template v-if="patient.gender === 'man'">{{
              $t("message.male")
            }}</template>
            <template v-else>{{ $t("message.female") }}</template>
          </td>
          <td v-if="columns.updated_at.show">
            {{ patient.updated_at | dateFormat }}
          </td>
          <td v-if="columns.created_at.show">
            {{ patient.created_at | dateFormat }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="patient"
              name="patients"
              :actions="actions"
              @edit="edit"
              @delete="destroy"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :append-to-body="true"
      :visible.sync="drawerCreate"
      size="60%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create
          :organizationId="organizationId"
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer>

    <el-drawer
      :append-to-body="true"
      :visible.sync="drawerUpdate"
      size="60%"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
      class="mytab_IdP"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmUpdate from "./components/patient-update";
import CrmCreate from "./components/patient-create";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "OrganizationPatientList",
  props: {
    organizationId: { default: null },
  },
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      organization_id: null,
    };
  },
  computed: {
    ...mapGetters({
      list: "patients/list",
      columns: "patients/columns",
      pagination: "patients/pagination",
      filter: "patients/filter",
      sort: "patients/sort",
      socials: "socials/list",
      organizations: "organizations/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.organization_id = this.organizationId;
    this.controlExcelData();
    if (this.socials && this.socials.length === 0) await this.loadSocials();

    if (this.organizations && this.organizations.length === 0)
      await this.loadOrganizations();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "patients/index",
      updateSort: "patients/updateSort",
      updateFilter: "patients/updateFilter",
      updateColumn: "patients/updateColumn",
      updatePagination: "patients/updatePagination",
      editModel: "patients/show",
      empty: "patients/empty",
      delete: "patients/destroy",
      refreshData: "patients/refreshData",
      loadSocials: "socials/index",
      loadOrganizations: "organizations/index",
    }),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        organization_id: this.organization_id,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>

